import React from 'react';
import Layout from '../layouts/index';
import BookImage from '../images/book-vocabularyBuilder1.png';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { ReactPhotoCollage } from 'react-photo-collage';
import Recommended from '../components/Recommended';
import Link from 'gatsby-link';
import Payhip from '../components/Payhip';

class CourseDetails extends React.Component {
	render() {
		const setting = {
			width: '100%',
			height: ['500px', '170px'],
			layout: [1, 4],
			photos: [
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/vocab-builder-preview/page1.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/vocab-builder-preview/page2.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/vocab-builder-preview/page3.png',
				},
				{
					source:
						'https://raw.githubusercontent.com/Caroline-Phung/HowToVietnameseAssets/master/Assets/vocab-builder-preview/page4.png',
				},
			],
			showNumOfRemainingPhotos: true,
		};
		return (
			<Layout>
				{/* Course info */}
				<div className="container-fluid margin-from-header bg-white">
					<div className="container pt-4 pt-md-8 pb-8">
						<div className="row d-flex justify-content-md-center reverse-flex-mobile">
							<div className="col-sm-12 col-md-5 d-flex flex-column justify-content-left mr-md-6">
								<h2>Vietnamese Vocabulary Builder by Theme</h2>
								<p>
									This book aims to help you expand your vocabulary easily,
									whether you are a beginner or an intermediate-level learner.
								</p>
								<p>
									Level: <b>Beginner - Intermediate</b>
								</p>
								{/*<h1 className="text-success">$9.99</h1>*/}
								<div className="row pl-1 mt-2">
									<Payhip productID="5N69g" label="Buy now - $9.99"></Payhip>
									{/* <OutboundLink
                    className="btn-simple btn-primary-outline box-shadow "
                    // href="https://gum.co/khngb?wanted=true"
                  >
                    Get now for $9
                  </OutboundLink> */}
									<button class="btn-simple btn-primary-solid ml-2">
										<Link to="/vietnamese-ebook-bundle" target="_blank">
											View Bundle
										</Link>
									</button>
								</div>
							</div>
							<div className="col-7 col-md-3 mb-2 align-self-center">
								<img
									src={BookImage}
									className="img-fluid float-right rounded"
									alt="Vietnamese Vocabulary Builder by Theme"
								></img>
							</div>
						</div>
					</div>
				</div>

				{/* Course contents */}
				<div className="container pt-md-10 pb-md-10 pt-6 pb-6">
					<div className="row justify-content-md-center">
						<div className="col-sm-12 col-md-8 ml-1 mr-1">
							<h2>Why this book?</h2>
							<p>
								Whether you have just started or have been learning Vietnamese
								for some time, having a <b>rich vocabulary</b> is so important
								to <b>understand other people</b> and{' '}
								<b>express your own opinions</b>. But learning lots of random
								words is never easy.
							</p>
							<p>
								Many of my students struggle the same! And unfortunately, there
								are <b>not enough useful vocabulary books</b> readily available
								to them.
							</p>
							<p>
								With this in mind, I have created this book for{' '}
								<b>beginner and intermediate</b> learners to expand their
								vocabulary easily.
							</p>
							<h2 className="pt-8">What can this book do?</h2>
							<ul>
								<li>
									Help you <b>focus on useful words and phrases</b> that are
									commonly used by native speakers.
								</li>
								<li>
									Help you <b>memorize words better</b> when they are organized
									into common themes, with 30 different topics.
								</li>
								<li>
									Help you easily <b>build up and use your vocabulary</b> by
									giving you related words and practical phrases.
								</li>
							</ul>
							<h2 className="pt-8">Book Preview</h2>
							<ReactPhotoCollage {...setting} />

							<h2 className="pt-8">What's included?</h2>
							<ul>
								<li>PDF eBook version - 1 file</li>
								<li>EPUB eBook version - 1 file</li>
							</ul>
						</div>
					</div>
				</div>
				<Recommended
					recommended={[
						'/vietnamese-ebook-bundle',
						'/vietnamese-grammar-book-bundle',
					]}
				/>
			</Layout>
		);
	}
}

export default CourseDetails;
